<template lang="pug">
.main-wrapper.unidades-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Sala</b>

    Dialog.dialogApagar(header='Remover Sala' :visible.sync='dialogApagar' :modal='true')
        p Deseja remover a sala <b>{{ dialogApagar_data.ds_area_atuacao }}</b>?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-danger(v-else label='Remover' @click='remove()')

    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-4
                label.form-label Sala:
                .p-inputgroup
                    InputText(placeholder='Sala'
                        @keyup.enter.native='() => !waiting && applyFilters()'
                        @input='(val) => !val && applyFilters()'
                        v-model='filters.nm_unidade'
                    )
                    Button(icon='jam jam-search' @click='applyFilters()' :disabled='waiting')
            .p-col-12.p-md-4
                label.form-label Unidade:
                ProgressBar(v-if="options.unidade.length == 0" mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Unidade...'
                        :options='options.unidade'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        @input='getSetores()'
                        v-model='filters.cd_unidade'
                        filter
                    )
            .p-col-12.p-md-4
                label.form-label Setor:
                ProgressBar(v-if="options.setor.length == 0" mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Setor...'
                        :options='options.setor'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_setor'
                        filter
                    )
            .p-col-12.p-md-4
                label.form-label Estabelecimento:
                ProgressBar(v-if="options.estabelecimentos.length == 0" mode="indeterminate")
                .p-inputgroup(v-else)
                    MultiSelect(
                        placeholder='Selecione Estabelecimento...'
                        :options='options.estabelecimentos'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_estabelecimento_list'
                        filter
                    )
            .p-col-12.p-md-4
                label.form-label Tipo de Sala:
                ProgressBar(v-if="options.tipo.length == 0" mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Tipo...'
                        :options='options.tipo'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.ie_tipo'
                        filter
                    )
            .p-col-12.p-md-2
                label.form-label Aberto:
                .p-inputgroup
                    SelectButton(
                        :options='options.aberto'
                        optionLabel='label'
                        optionValue='value'
                        @input='applyFilters()'
                        v-model='filters.ie_status'
                    )
            .p-col-12.p-md-2
                Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')

    .ta-right.my-2
        Button(label='Adicionar' icon="jam jam-plus" @click="$router.push(`/sala/salvar/`)")

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.nm_setor" style='position: relative')
                        .ta-left
                            p <b>Sala:</b> {{ props.data.nm_sala }}
                            p <b>Setor:</b> {{ props.data.nm_setor }}
                            p <b>Unidade:</b> {{ props.data.nm_unidade }}
                            p <b>Estabelecimento:</b> {{ props.data.nm_estabelecimento }}
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/sala/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Sala')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value="list")
                Column(headerStyle='width: 70px' bodyStyle='text-align:center' header='Status')
                    template(#body='props')
                        ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
                        .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data)')
                            i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")

                Column(headerStyle='width: 29%;' field='nm_sala' header='Sala')
                    template(#body='props')
                        span(v-tooltip.top="props.data.nm_sala" style='cursor: pointer;') {{ props.data.nm_sala }} <br>
                            em(style='font-size: 10px') Tipo: {{ tipoDominio[props.data.ie_tipo] }}

                Column(headerStyle='width: 29%;' field='nm_setor' header='Setor')
                    template(#body='props')
                        span(v-tooltip.top="props.data.nm_setor" style='cursor: pointer;') {{ props.data.nm_setor }}

                Column(headerStyle='width: 29%;' field='nm_unidade' header='Unidade')
                    template(#body='props')
                        span(v-tooltip.top="props.data.nm_unidade" style='cursor: pointer;') {{ props.data.nm_unidade }}

                Column(headerStyle='width: 12%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/sala/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .unidades-listar {
        .status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
			}
		}
		.waitingStatus, .waitingObservacoes {
			width: 29px;
			height: auto;
		}
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import SelectButton from 'primevue/selectbutton'

    import { UnidadesMedclub, Estabelecimento, DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import axios from "axios"

    const _ = require('lodash')

    export default {

        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'sala') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},

        created () {
            this.getUnidades()
            this.getSetores()
            this.getEstabelecimentos()
            this.getDominio()
            // this.applyFilters()
        },
        components: {
            ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown, MultiSelect,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, SelectButton, },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingStatus: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                cancelToken: null,
                params: {},
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 300, { leading: true }),
                filters: {
                    ie_tipo: null,
                    nm_unidade: '',
                    cd_unidade: null,
                    cd_setor: null,
                    cd_estabelecimento_list: [],
                    ie_classificacao: 'SET',
                    ie_status: 'true',
                },
                tipoSetor: [],
                options: {
                    tipo: [],
                    unidade: [],
                    estabelecimentos: [],
                    setor: [],
                    aberto: [{label: 'Sim', value: 'true'}, {label: 'Não', value: 'false'}]
                },
                tipoDominio: [],
                order: '-id',
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0,
                },
            }
        },
        methods: {
            getDominio(){
                DominioValor.findAll({ds_mnemonico: 'TIPO_SALA'}).then(response => {
                    this.options.tipo.push({label: "- Selecione -", value: null})
                    if (response.status === 200) {
                        response.data['TIPO_SALA'].valores.forEach(item => {
                            this.tipoDominio[item.ie_valor] = item.ds_valor
                            this.options.tipo.push({label: item.ds_valor, value: item.ie_valor})
                        })
                    }
                })
            },
            resetFilters() {
				this.filters.nm_unidade = ''
				this.filters.cd_unidade = null
				this.filters.cd_setor = null
				this.filters.cd_estabelecimento_list = []
				this.filters.ie_status = null
			},
			limparFiltros() {
				this.resetFilters()
				this.applyFilters()
			},
            alterarStatus (item) {
				this.waitingStatus = item.id
				UnidadesMedclub.saveSala({ id: item.id, ie_status: ! item.ie_status }).then(response => {
					this.waitingStatus = 0
					if (([200, 201]).includes(response.status)) {
						item.ie_status = ! item.ie_status
						if (item.ie_status)
							this.$toast.success('Sala habilitada', { duration: 3000 })
						else
							this.$toast.info('Sala desabilitada', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
            getEstabelecimentos(){
                Estabelecimento.findAllClean({order: 'nm_fantasia'}).then(response => {
                    if (response.status === 200) {
                        response.data.forEach(item => 
                            this.options.estabelecimentos.push({label: item.nm_fantasia, value: item.id})
                        )
                    }
                })
            },
            getSetores (){
                this.filters.cd_setor = null
                this.options.setor = []
                let aux = {}
                if(this.filters.cd_unidade) aux = {cd_unidade: this.filters.cd_unidade}
                UnidadesMedclub.findAllSetor(aux).then(response=>{
                    this.options.setor.push({ value: null, label: '- Selecione -' })
                    if (response.status == 200) {
                        response.data.forEach(item => {
                            this.options.setor.push({label: item.nm_setor, value: item.id})
                        })
                    }
                })
            },
            getUnidades (){
                UnidadesMedclub.findAll().then(response=>{
                    this.options.unidade.push({ value: null, label: '- Selecione -' })
                    if (response.status == 200) {
                        response.data.forEach(item => {
                            this.options.unidade.push({label: item.nm_unidade, value: item.id})
                        })
                    }
                })
            },
            getList (params) {
                if (this.cancelToken) this.cancelToken.cancel()
                this.cancelToken = axios.CancelToken.source()

                this.waiting = true
                return UnidadesMedclub.findAllSala(params, this.cancelToken.token).then(response => {
                    if (!response) return
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    this.cancelToken = null
                })
            },
            applyFilters(page, force) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page, order: this.order }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })

                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params) && !force) {
                    if (!this.waiting) {
                        this.waiting = true
                        setTimeout(() => this.waiting = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
                    this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            remove () {
                this.waitingApagar = true
                UnidadesMedclub.removeSala(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Sala REMOVIDA com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters(1, true)
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>
